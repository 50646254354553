var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Frequently Asked Questions")]), _c('v-expansion-panels', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" What is Inside Travel – Canada’s Travel Industry Directory? ")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "answer",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v("Inside Travel is a handy supplier directory for Canadian travel advisors. It includes contact details for Air Consolidators, Tour Operators & Wholesalers, Tourism Offices, and other travel industry suppliers.")])], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" What are the benefits of joining Inside Travel? ")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "answer",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v("As a travel advisor you can "), _c('a', {
    on: {
      "click": _vm.showSignupDialog
    }
  }, [_vm._v("Sign up")]), _vm._v(" for a free account. You’ll be able to access contact details for sales support and business development managers, travel agent portals, and much more – at any time of day or night.")])], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("Is there a fee to join?")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "answer",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v("No, Inside Travel is fast, friendly, and FREE for travel advisors! ")])], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("How do I search the directory? ")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "answer",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v("To start, just type into the search box at the top. Enter a supplier name, destination, or a specialty. Search results will appear when you type at least three letters! You can then refine your results by selecting from the filters on the left. You can also browse through the list of suppliers, specialties, and destinations by clicking on any of them. If you want to go back to the homepage, just click on the “Inside Travel” logo at the top left. ")])], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("How do I update a business listing? ")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "answer",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v(" Improving the information for a business listing can be done easily: "), _c('ol', [_c('li', [_vm._v("Search for the business.")]), _c('li', [_vm._v(" On the business profile page, click on the “SUGGEST AN EDIT” link. ")]), _c('li', [_vm._v(" Provide as much up-to-date information about the business as you can. We’ll verify the information, and we’ll update the business profile as quickly as possible. ")])])])], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("How do I add a business listing? ")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "answer",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v("Simply "), _c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("contact us")]), _vm._v(" and provide information about the business. We’ll verify the information, and we’ll add the business profile as quickly as possible ")], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("How frequently do you update the content on your site? ")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "answer",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v("Inside Travel is regularly updated. We need to verify that the information is accurate. ")])], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("Why haven’t I heard of Inside Travel? It seems pretty cool! ")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "answer",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v("Inside Travel — Canada’s Travel Industry Directory was first printed in 1992 and is now accessible online. You can learn more about us "), _c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/about"
    }
  }, [_vm._v("here")]), _vm._v(". ")], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("What else does Inside Travel offer? ")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "answer",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v("Inside Travel is part of International Publishing Group Inc based in Toronto, Canada. We specialize in custom publishing, marketing, advertising and graphic design services for the travel industry. You can find our clients’ work samples at "), _c('a', {
    attrs: {
      "href": "https://www.interpub.ca",
      "target": "_blank"
    }
  }, [_vm._v("www.interpub.ca")]), _vm._v(". ")])], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("Question & Comments on inside.travel? ")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "answer",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v("We’re interested in hearing from you! If you have any questions, comments, or if there’s anything we can do for you, please let us know by "), _c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("contacting us")]), _vm._v(". ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }