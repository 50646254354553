<template>
  <div class="about">
    <div class="row">
      <div class=".dynamic-max-height-column text">
        <h2>
          A handy directory of travel trade suppliers for Canadian travel
          advisors
        </h2>
        <p>
          First published in 1992, Inside Travel — Canada's Travel Industry
          Directory is now available online. It's faster, friendlier, and FREE!
        </p>

        <p>Find suppliers, destinations, specialties, and much more.</p>

        <p>
          The searchable database includes air consolidators, airlines, car
          rentals, cruise lines, embassies, hotels & resorts, railways, tour
          operators & wholesalers, tourism offices, travel insurance, travel
          organizations, travel technology, and more!
        </p>

        <p>
          Supplier sales contacts include address, telephone number, email,
          website, team members and social media accounts.
        </p>
        <p>
          <a @click="showSignupDialog">Sign up for free online access</a>. If
          you have any questions,
          <router-link style="text-decoration: none" to="/contact"
            >contact us</router-link
          >.
        </p>
      </div>
      <div class="image">
        <img src="../assets/images/it_book.png" alt="book" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data: () => ({}),

  methods: {
    showSignupDialog: function () {
      this.$root.$emit("showSignupDialog");
    },
  },
};
</script>

<style scoped>
.about {
  margin: 1em;
}
.row {
  display: flex;
  margin-top: 1em;
}

img {
  vertical-align: top; /* to get rid of default bottom padding in images */
}

.text {
  width: 50%;
}

.text h2 {
  margin: 0 1em 1em 0;
}

.image {
  width: 50%;
  display: flex;
  /* justify-content: flex-end; */
}

.row > div:not(.dynamic-max-height-column) {
  position: relative;
}

.row > div:not(.dynamic-max-height-column) > img {
  position: absolute;
  max-height: 100%;
}
</style>
