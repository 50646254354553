var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: ".dynamic-max-height-column text"
  }, [_c('h2', [_vm._v(" A handy directory of travel trade suppliers for Canadian travel advisors ")]), _c('p', [_vm._v(" First published in 1992, Inside Travel — Canada's Travel Industry Directory is now available online. It's faster, friendlier, and FREE! ")]), _c('p', [_vm._v("Find suppliers, destinations, specialties, and much more.")]), _c('p', [_vm._v(" The searchable database includes air consolidators, airlines, car rentals, cruise lines, embassies, hotels & resorts, railways, tour operators & wholesalers, tourism offices, travel insurance, travel organizations, travel technology, and more! ")]), _c('p', [_vm._v(" Supplier sales contacts include address, telephone number, email, website, team members and social media accounts. ")]), _c('p', [_c('a', {
    on: {
      "click": _vm.showSignupDialog
    }
  }, [_vm._v("Sign up for free online access")]), _vm._v(". If you have any questions, "), _c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("contact us")]), _vm._v(". ")], 1)]), _vm._m(0)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/it_book.png"),
      "alt": "book"
    }
  })]);
}]

export { render, staticRenderFns }