<template>
  <div>
    <h1>Frequently Asked Questions</h1>
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          What is Inside Travel – Canada’s Travel Industry Directory?
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-card elevation="0" class="answer"
            >Inside Travel is a handy supplier directory for Canadian travel
            advisors. It includes contact details for Air Consolidators, Tour
            Operators & Wholesalers, Tourism Offices, and other travel industry
            suppliers.</v-card
          >
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          What are the benefits of joining Inside Travel?
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-card elevation="0" class="answer"
            >As a travel advisor you can
            <a @click="showSignupDialog">Sign up</a> for a free account. You’ll
            be able to access contact details for sales support and business
            development managers, travel agent portals, and much more – at any
            time of day or night.</v-card
          >
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header
          >Is there a fee to join?</v-expansion-panel-header
        >
        <v-expansion-panel-content
          ><v-card elevation="0" class="answer"
            >No, Inside Travel is fast, friendly, and FREE for travel advisors!
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header
          >How do I search the directory?
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-card elevation="0" class="answer"
            >To start, just type into the search box at the top. Enter a
            supplier name, destination, or a specialty. Search results will
            appear when you type at least three letters! You can then refine
            your results by selecting from the filters on the left. You can also
            browse through the list of suppliers, specialties, and destinations
            by clicking on any of them. If you want to go back to the homepage,
            just click on the “Inside Travel” logo at the top left.
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header
          >How do I update a business listing?
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-card elevation="0" class="answer">
            Improving the information for a business listing can be done easily:
            <ol>
              <li>Search for the business.</li>
              <li>
                On the business profile page, click on the “SUGGEST AN EDIT”
                link.
              </li>
              <li>
                Provide as much up-to-date information about the business as you
                can. We’ll verify the information, and we’ll update the business
                profile as quickly as possible.
              </li>
            </ol>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header
          >How do I add a business listing?
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-card elevation="0" class="answer"
            >Simply
            <router-link style="text-decoration: none" to="/contact"
              >contact us</router-link
            >
            and provide information about the business. We’ll verify the
            information, and we’ll add the business profile as quickly as
            possible
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header
          >How frequently do you update the content on your site?
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-card elevation="0" class="answer"
            >Inside Travel is regularly updated. We need to verify that the
            information is accurate.
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header
          >Why haven’t I heard of Inside Travel? It seems pretty cool!
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-card elevation="0" class="answer"
            >Inside Travel — Canada’s Travel Industry Directory was first
            printed in 1992 and is now accessible online. You can learn more
            about us
            <router-link style="text-decoration: none" to="/about"
              >here</router-link
            >.
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header
          >What else does Inside Travel offer?
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-card elevation="0" class="answer"
            >Inside Travel is part of International Publishing Group Inc based
            in Toronto, Canada. We specialize in custom publishing, marketing,
            advertising and graphic design services for the travel industry. You
            can find our clients’ work samples at
            <a href="https://www.interpub.ca" target="_blank">www.interpub.ca</a
            >.
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header
          >Question & Comments on inside.travel?
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-card elevation="0" class="answer"
            >We’re interested in hearing from you! If you have any questions,
            comments, or if there’s anything we can do for you, please let us
            know by
            <router-link style="text-decoration: none" to="/contact"
              >contacting us</router-link
            >.
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { HOST } from "../api/config";
// import debounce from "debounce";

export default {
  name: "FAQ",
  data: () => ({}),

  computed: {},

  watch: {},

  mounted() {
    this.host = HOST;
  },

  beforeMount() {},

  methods: {
    showSignupDialog: function () {
      this.$root.$emit("showSignupDialog");
    },
  },
};
</script>

<style scoped>
.answer {
  font-size: 0.9em;
  background-color: whitesmoke;
  padding: 1em;
}
</style>
